<template>
    <div class=container>
    <table class=table width="73%" border="0" cellspacing="30" cellpadding="0"><tbody><tr>
                  <td align="left"><div align="center"><font color="#000000">					  </font>
                    <div align="center">About the Database  </div>
                    <br>
                    <table width="98%" border="0" align="center" cellpadding="10" bgcolor="FFFFCC" height="199">
                      <tbody><tr>
                        <td height="295"><span class="style2">Understanding and predicting the appearance of outdoor scenes under arbitrary lighting and weather conditions is a fundamental problem in computer vision and computer graphics. Solutions to this problem have implications for several application domains including image-based rendering, remote sensing, and visual surveillance. The appearance of an outdoor scene is highly complex and depends on factors such as viewing direction, scene structure and material properties (sand, brick, grass), illumination (sun, moon, stars, street lamps), atmospheric condition (clear air, haze, mist, fog, rain) and aging (patina, corrosion, rust) of materials. Over time, these factors change, altering the way a scene appears. A large set of images is required to study the entire variability in scene appearance. The <b>Columbia Weather and Illumination Database (WILD)</b> is being collected for this purpose.<br>
                        <br>
      The WILD database consists of high quality (1520 x 1008 pixels, 12 bits per pixel) calibrated color (RGB) images of an outdoor scene captured every hour for over 6 months. The data collection is ongoing and we plan to acquire images for one year. The dataset covers a wide range of daylight and night illumination conditions, weather conditions and all 4 seasons. This webpage describes in detail the image acquisition and sensor calibration procedures. The images are tagged with a variety of ground truth data such as atmospheric conditions (weather, visibility, sky condition, humidity, temperature) and approximate scene depths.</span></td>
                      </tr>
                    </tbody></table>
                    <!-- <br> -->
                    <!-- <table width="100%" border="0">
                      <tbody><tr>
                        <td width="49%"><div align="center"><font face="Verdana, Arial, Helvetica, sans-serif" size="2">[ <a href="index.php">Back to WILD Home</a> ]</font></div></td>
                      </tr>
                    </tbody></table> -->
                    <!-- <br>
                    <br> -->
                    <!-- <table width="98%" border="0" align="center">
                      <tbody><tr>
                        <td height="16" valign="top"><div align="center" class="style2">
                          <div align="left"><font color="#000033" face="Verdana, Arial, Helvetica, sans-serif">[ <a href="#scene">Scene Description</a> ]</font></div>
                        </div></td>
                        <td valign="top"><div align="left"><span class="style2"><font color="#000033" face="Verdana, Arial, Helvetica, sans-serif">[ <a href="#image">Image Quality, Quantity and Format</a> ]</font></span></div></td>
                        <td valign="top"><div align="left"><span class="style2"><font color="#000033" face="Verdana, Arial, Helvetica, sans-serif">[ <a href="#hardware">Acquisition Hardware</a> ]</font></span></div></td>
                      </tr>
                      <tr>
                        <td width="31%" height="16" valign="top"><div align="center" class="style2">
                          <div align="left"><font color="#000033" face="Verdana, Arial, Helvetica, sans-serif">[ <a href="#calibration">Calibration</a> ]</font></div>
                        </div></td>
                        <td width="44%" valign="top"><div align="center" class="style2">
                          <div align="left"><font color="#000033" face="Verdana, Arial, Helvetica, sans-serif">[ <a href="#ground">Ground Truth</a> ]</font></div>
                        </div></td>
                        <td width="25%"><div align="center" class="style2"></div></td>
                      </tr>
                    </tbody></table> -->
                    <div align="left"><br>
                      <br>
                      <br>
                        <a name="scene"></a> <font face="Verdana, Arial, Helvetica, sans-serif" size="2" color="000099"><b><font color="#000033" size="3">The Scene</font></b></font><br>
                      <br>
                        <span class="style2"><font face="Verdana, Arial, Helvetica, sans-serif">The scene we image is an urban scene in uptown Manhattan with buildings, trees and sky. The distances of these buildings range from about 20 meters to about 5 kilometers. The large distance range facilitates the observation of weather effects on scene appearance. See Figure for the entire field of view.</font></span><br>
                      <br>
                    </div>
                    <table width="47%" border="1" height="453" align="center">
  <tbody><tr>
    <td align="center"><img src="https://cave.cs.columbia.edu/old/software/wild/images/full_scene.jpg" width="640" height="480"></td>
  </tr>
</tbody></table>
<br>
<!-- <table width="100%" border="0">
  <tbody><tr>
    <td width="65%"><div align="right"><font size="2" face="Verdana, Arial, Helvetica, sans-serif">[ <a href="#top">Top</a> ]</font></div></td>
  </tr>
</tbody></table> -->
<div align="left"><br>
    <br>
    <br>
    <br>
    <a name="image"></a> <font face="Verdana, Arial, Helvetica, sans-serif" size="2" color="000099"><b><font color="#000033" size="3">Image Quality, Quantity and Format </font></b></font><font color="#000033"><br>
    </font><br>
    <span class="style2"><font face="Verdana, Arial, Helvetica, sans-serif">Images are captured automatically every hour for 20 hours each day (on an average). The spatial resolution of each image is 1520 x 1008 pixels and the intensity resolution is 10 bits per pixel per color channel. Currently, we have acquired images for over 150 days. In total, the database has around 3000 images.<br>
  To capture both subtle and large changes in illumination and weather, high dynamic range images are required. So, the images are acquired with multiple exposures (by changing the camera shutter speed while keeping the aperture constant) and apply existing techniques to compute a high dynamic range image (approx. 12 bits per pixel per color channel) of the scene. See the section on calibration for more details.<br>
  The images are stored in uncompressed 48-bit RGB tif format which can be read using software such as Photoshop, xv, or matlab. </font></span> <br>
    <br>
  </div>
<!-- <table width="100%" border="0">
    <tbody><tr>
      <td width="5%"><div align="right"><font size="2" face="Verdana, Arial, Helvetica, sans-serif">[ <a href="#top">Top</a> ]</font></div></td>
    </tr>
  </tbody></table> -->
  <div align="left"><br>
      <br>
      <br>
      <br>
      <a name="hardware"></a> <font face="Verdana, Arial, Helvetica, sans-serif" size="2" color="000099"><b><font color="#000033" size="3">Acquisition Hardware </font></b></font><br>
      <br>
      <span class="style2"> <font face="Verdana, Arial, Helvetica, sans-serif">The digital camera used for image capture is a single CCD KODAK Professional DCS 315 (see Figure). As usual, irradiance is measured using 3 wideband R, G, and B color filters. An AF Nikkor 24 mm - 70 mm zoom lens is attached to the camera. The setup for acquiring images is shown in Figure. The camera is rigidly mounted over a pan-tilt head which is fixed rigidly to a weather-proof box. The weather-proof box is coated on the inside with two coats of black paint to prevent inter-reflections within the box. An anti-reflection glass plate is attached to the front of this box through which the camera views the scene. Between the camera and the anti-reflection plate, is a filter holder (for, say, narrow band spectral filters). The entire box with the camera and the anti-reflection glass plate is mounted on a panel rigidly attached to a window. </font></span> <br>
      <br>
  </div>
  <table width="52%" border="1" height="213" align="center">
    <tbody><tr>
      <td align="center" height="160"><img src="https://cave.cs.columbia.edu/old/software/wild/images/hardware.jpg" width="700" height="167"></td>
    </tr>
  </tbody></table>
  <br>
  <!-- <table width="100%" border="0">
    <tbody><tr>
      <td width="5%"><div align="right"><font size="2" face="Verdana, Arial, Helvetica, sans-serif">[ <a href="#top">Top</a> ]</font></div></td>
    </tr>
  </tbody></table> -->
  <div align="left"><br>
      <br>
      <br>
      <br>
      <a name="calibration"></a> <font face="Verdana, Arial, Helvetica, sans-serif" size="2" color="000099"><b><font color="#000033" size="3">Calibration</font></b></font><br>
      <br>
      <span class="style2"> <font face="Verdana, Arial, Helvetica, sans-serif">The various calibration and preprocessing steps performed on the image set are as follows: </font></span><br>
      <br>
  </div>
  <table width="99%" border="0" height="752">
  <tbody><tr>
    <td width="5%">&nbsp;</td>
    <td width="95%"><font face="Verdana, Arial, Helvetica, sans-serif" size="2" color="#000033"><b>Geometric Calibration</b></font> <br>
            <br>
            <font face="Verdana, Arial, Helvetica, sans-serif" size="2"> Geometric calibration constitutes the estimation of the geometric mapping between 3D scene points and their image projections. Since the calibration was done in a location different from that used for image acquisition, only the intrinsic parameters of the camera are estimated. Intrinsic parameters include the effective focal length, f, skew, s, center of projection (u0, v0) and distortion parameters, C1 ... Cn (radial) and P1, P2 (tangential). <br>
      The images of a planar checkerboard pattern were captured under various orientations (see figure). The corresponding corners of the checkerboard patterns in these images were marked. These corresponding corner points were input to a calibration routine [1] to obtain the intrinsic parameters. The table shows the estimated intrinsic parameters. The CCD pixels are square and hence skew is assumed to be 1. The deviation of the principal point from the image center is given by u0, v0. Only the first radial distortion parameter, C1, is shown. The remaining distortion parameters are set to zero. </font><br>
            <br>
            <table width="60%" border="1" align="center">
              <tbody><tr>
                <td><img src="https://cave.cs.columbia.edu/old/software/wild/images/calibration_equations.jpg" width="640" height="439"></td>
              </tr>
            </tbody></table>
            <br>
            <font size="2" face="Verdana, Arial, Helvetica, sans-serif">&nbsp; </font><br>
            <table width="97%" border="0" cellpadding="5">
              <tbody><tr>
                <td width="1%" height="2"><font size="2" face="Verdana, Arial, Helvetica, sans-serif"><b>1.</b></font></td>
                <td width="99%" height="2"><font size="2" face="Verdana, Arial, Helvetica, sans-serif">Bouguet, J.Y.: Camera calibration toolbox. (In: <a href="http://www.vision.caltech.edu/bouguetj/calib_doc/" target="_blank">http://www.vision.caltech.edu/bouguetj/calib_doc/</a> ) </font></td>
              </tr>
            </tbody></table>
            <br>
            <br>
            <font face="Verdana, Arial, Helvetica, sans-serif" size="2" color="#000033"><b>Radiometric Response Function</b></font> <br>
            <br>
            <font face="Verdana, Arial, Helvetica, sans-serif" size="2"> Analysis of image irradiance using measured pixel brightness requires the radiometric response of the sensor. The radiometric response of a sensor is the mapping, g, from image irradiance, I, to measured pixel brightness, M : M = g(I) Then, the process of obtaining I from M: I = g^(-1)(M) upto a global scale factor, is termed as radiometric calibration. </font><br>
            <br>
            <br>
            <font face="Verdana, Arial, Helvetica, sans-serif" size="2" color="#000033"><b>Radiometric Calibration</b></font> <br>
            <br>
            <font face="Verdana, Arial, Helvetica, sans-serif" size="2"> The response functions of CCD cameras (without considering the gamma or color corrections applied to the CCD readouts) are close to linear. The response functions were computed for the 3 RGB channels separately using Mitsunaga and Nayar's [2] radiometric self-calibration method. In this method, images captured with multiple exposures and the their relative exposure values are used to estimate the inverse response function in polynomial form. The results of the calibration are shown in the plots of the figure. Notice that the response functions of R, G, and B are linear and they have different slopes - 1.5923, 1.005 and 0.982 respectively. To balance the colors, we normalize the response functions by the respective slopes. </font><br>
            <font size="2" face="Verdana, Arial, Helvetica, sans-serif"><br>
            </font>
            <!-- <table width="96%" border="0" cellpadding="5">
              <tbody><tr>
                <td width="1%" height="2" valign="top"><font size="2" face="Verdana, Arial, Helvetica, sans-serif"><b>2.</b></font></td>
                <td width="99%" height="2">
				print_paperentry_byid: more than 2 or no entry matches.<br>
				</td>
              </tr>
            </tbody></table> -->
            <br>
            <br>
            <font face="Verdana, Arial, Helvetica, sans-serif" size="2" color="000099"><b><font color="#000033">High Dynamic Range Imaging</font></b></font> <font color="#000033"><br>
            </font><br>
            <font face="Verdana, Arial, Helvetica, sans-serif" size="2"> The images taken with different exposures are linearized using the computed response function. A high dynamic range image irradiance map is then obtained by using a weighted combination of the linearized images. This image has significantly more dynamic range then any of the original images taken with single exposures [3]. The high dynamic range can prove very useful when analyzing both subtle and large changes in weather and illumination.<br>
      Since the illumination intensity is expected to vary with time, the set of exposures are chosen adaptively. First, an auto-exposure image is taken and its shutter speed is noted. Then 4 more images are captured with exposures around this auto-exposure value. This type of adaptive exposure selection is commonly used by photographers and is called exposure bracketing. The High Dynamic Range Image is normalized to the same intensity scale using the auto-exposure shutter speed reported by the camera. </font><br>
            <br>
            <table width="47%" border="1" height="451" align="center">
              <tbody><tr>
                <td><img src="https://cave.cs.columbia.edu/old/software/wild/images/high_dynamic_range.jpg" width="640" height="368"></td>
              </tr>
            </tbody></table>
            <font size="2" face="Verdana, Arial, Helvetica, sans-serif"><br>
            </font>
            <!-- <table width="99%" border="0" cellpadding="5">
              <tbody><tr>
                <td width="1%" height="2" valign="top"><div align="right"><font size="2" face="Verdana, Arial, Helvetica, sans-serif"><b>3.</b></font></div></td>
                <td width="99%" height="2">
				print_paperentry_byid: more than 2 or no entry matches.<br>
				
				</td>
              </tr>
            </tbody></table> -->
            <br>
            <br>
            <font face="Verdana, Arial, Helvetica, sans-serif" size="2" color="#000033"><b>Image Registration</b></font> <br>
            <br>
            <font face="Verdana, Arial, Helvetica, sans-serif" size="2"> Despite rigidly fixing the camera on a tripod mount, due to prolonged usage the camera is very slowly bending due to its own weight. This causes minor misalignments between images. Fortunately, images captured within a few days are still aligned correctly. Only when comparing images captured in different weeks and months, misalignment of a few pixels is noted. The image registration utility of matlab was used to randomly align pairs of images separated by hours, days, weeks and months. The table shows the rotation and translation to align such image pairs. </font><br>
            <br>
            <table width="37%" border="1" height="400" align="center">
              <tbody><tr>
                <td><img src="https://cave.cs.columbia.edu/old/software/wild/images/image_registration.jpg" width="640" height="409"></td>
              </tr>
          </tbody></table></td>
  </tr>
</tbody></table>
<br>
<table width="100%" border="0">
  <tbody><tr>
    <td width="5%"><div align="right"><font size="2" face="Verdana, Arial, Helvetica, sans-serif">[ <a href="#top">Top</a> ]</font></div></td>
  </tr>
</tbody></table>
<div align="left"><br>
    <br>
    <br>
    <br>
    <a name="ground"></a> <font face="Verdana, Arial, Helvetica, sans-serif" size="2" color="000099"><b><font color="#000033" size="3">Ground Truth</font></b></font><br>
    <br>
    <font face="Verdana, Arial, Helvetica, sans-serif" size="2"> Any database is incomplete without the accompanying ground truth. The images are tagged with a variety of ground truth information. Most important categories of the ground truth we collected are scene depth and weather information. </font><br>
    <br>
</div>
<table width="99%" border="0" height="365">
  <tbody><tr>
    <td width="5%" height="336">&nbsp;</td>
    <td width="95%" height="336"><font face="Verdana, Arial, Helvetica, sans-serif" size="2" color="#000033"><b>Approximate Depth Information</b></font> <br>
            <br>
            <font face="Verdana, Arial, Helvetica, sans-serif" size="2"> The depths of scene points are mainly obtained using satellite digital orthophotos supplied by the United States Geological Survey [4]. Arcview [5] is a mapping software that is used to measure the orthographic distance between two scene points (visible in the orthophotos) up to an accuracy of 1 meter. See figure for an example of a satellite orthophoto. Note that accurate depth is not available at all pixels. However, since the field of view consists of mainly vertical buildings, planar models have be used. </font><font face="Verdana, Arial, Helvetica, sans-serif" size="2">Click [&nbsp;<a href="https://cave.cs.columbia.edu/old/software/wild/images/original_depthmap.jpg">here</a>&nbsp;] to download the actual depth map. NOTE: Compute the depths D = 17 * (256 - I) where, I is the brightness of each pixel. </font><br>
            <br>
            <table width="47%" border="1" height="423" align="center">
              <tbody><tr>
                <td height="305"><img src="https://cave.cs.columbia.edu/old/software/wild/images/approx_depth_info.jpg" width="640" height="420"></td>
              </tr>
            </tbody></table>
            <br>
            <table width="100%" border="0" cellpadding="5" height="37">
              <tbody><tr>
                <td width="1%" valign="top" height="19"><div align="right"><font size="2" face="Verdana, Arial, Helvetica, sans-serif"><b>4.<br>
              5.</b></font></div></td>
                <td width="99%" height="19"><font size="2" face="Verdana, Arial, Helvetica, sans-serif">USGS: U.S. Geological Survey Mapping home page. (In: http://mapping.usgs.gov)<br>
            ESRI: The ESRI home page. (In: http://www.esri.com)</font></td>
              </tr>
            </tbody></table>
            <br>
            <br>
            <font face="Verdana, Arial, Helvetica, sans-serif" size="2" color="#000033"><b>Weather</b></font> <br>
            <br>
            <font face="Verdana, Arial, Helvetica, sans-serif" size="2"> Every hour standard weather information is automatically collected from the National Weather Service web sites [6]. This includes information about sky condition (sunny, cloudy), weather condition (clear, fog, haze, rain), visibility, temperature, pressure, humidity and wind (see figure). Such information can be used to estimate the scattering coefficient of the atmosphere [7]. </font><br>
            <br>
            <table width="32%" border="1" height="302" align="center">
              <tbody><tr>
                <td><img src="https://cave.cs.columbia.edu/old/software/wild/images/ground_truth.jpg" width="600" height="400"></td>
              </tr>
            </tbody></table>
            <br>
            <table width="100%" border="0" cellpadding="5">
              <tbody><tr>
                <td width="1%" valign="top" height="41"><div align="right"><font size="2" face="Verdana, Arial, Helvetica, sans-serif"><b>6.<br>
              7.</b></font></div></td>
                <td width="99%" height="41" valign="top"><font size="2" face="Verdana, Arial, Helvetica, sans-serif">NWS: The national weather service home page. (In: http://www.nws.noaa.gov)<br>
            Acharya, P.K., Berk, A., Anderson, G.P., Larsen, N.F., Tsay, S.C., Stamnes, K.H.: Modtran4: Multiple scattering and BRDF upgrades to modtran. SPIE Proc. Optical Spectroscopic Techniques and Instrumentation for Atmospheric and Space Research III 3756 (1999) </font></td>
              </tr>
            </tbody></table>
            <br>
            <br>
            <font face="Verdana, Arial, Helvetica, sans-serif" size="2" color="#000033"><b>Ephemeric Data</b></font> <br>
            <br>
            <font face="Verdana, Arial, Helvetica, sans-serif" size="2"> The position (longitude, latitude and altitude) of the sensor is included in the database. This information along with the date and time of day, can be used to accurately compute sun and moon orientation relative to the sensor. For exact equations, see [8, 9].<br>
            <br>
            </font>
            <table width="100%" border="0" cellpadding="5">
              <tbody><tr>
                <td width="1%" valign="top" height="36"><div align="right"><font size="2" face="Verdana, Arial, Helvetica, sans-serif"><b>8.<br>
              9.</b></font></div></td>
                <td width="99%" valign="top" height="36"><font face="Verdana, Arial, Helvetica, sans-serif" size="2">Aurora: Sun angle basics. (In: http://aurora.crest.org/basics/solar/angle/)<br>
            Naval-Observatory: The Astronomical Alamanac for the Year 2001. US R. G. O Government Printing Office (2001)</font></td>
              </tr>
            </tbody></table>
            <br>
            <font color="#000033"><br>
            <font face="Verdana, Arial, Helvetica, sans-serif" size="2"><b>Ground Truth File Format</b></font></font> <br>
            <br>
            <font face="Verdana, Arial, Helvetica, sans-serif" size="2"> The ground truth data is presented in HTML. A matlab tool to parse this file and search the attributes is given {here}. </font></td>
  </tr>
</tbody></table>
<br>
<!-- <table width="100%" border="0">
  <tbody><tr>
    <td width="5%"><div align="right"><font size="2" face="Verdana, Arial, Helvetica, sans-serif">[ <a href="#top">Top</a> ]</font></div></td>
  </tr>
</tbody></table> -->
<br>
<table width="100%" border="0">
  <tbody><tr>
    <td width="49%"><div align="center"><font face="Verdana, Arial, Helvetica, sans-serif" size="2">[ <router-link to="/repository/WILD">Back to WILD Home</router-link> ]</font></div></td>
  </tr>
</tbody></table>
<br>
<br>
<table width="100%" border="0" align="center">
  <tbody><tr>
    <td width="49%"><div align="center"><font color="#000033" size="2" face="Verdana, Arial, Helvetica, sans-serif">Contact: <a href="mailto:wild@cs.columbia.edu">wild@cs.columbia.edu</a></font></div></td>
  </tr>
</tbody></table>
<br>
                  </div></td>
                </tr>
        </tbody></table>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>